input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: #FFF;
    -webkit-text-fill-color: white !important;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    margin: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #2e5099;
    border-radius:5px;
   
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }